import './Whatsapp.css';

export function Whatsapp() {
  return (
    <div>
      <a href="https://api.whatsapp.com/send?phone=554498361616&text=Ol%C3%A1,%20gostaria%20de%20agendar%20uma%20consulta!" className="float" target="_blank" rel="noreferrer">
      <i className="fab fa-whatsapp my-float"></i>
      </a>
    </div>
  )
}