import './Localization.css';

export default function Localization () {
  return (
    <div className="maps-container">
      <h2 className="review-title">Localização</h2>
      <div className="review-line" />
      <div className="elfsight-app-c05b6daa-cfd2-4b73-9758-00ed1deb0453"></div>
    </div>
  )
}